.menuBtn{
    background-color: #f2f2f2;
    margin-bottom: 2px;
}
.menuItem{
    display: flex;
    justify-content: space-between;
}
.menuItem .options{
    visibility: hidden;
}
.menuItem:hover .options{
    visibility: visible;
}
.textHidden{
    display: inline-block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}