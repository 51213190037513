.formContent{
    display: flex;
    /* justify-content: space-between; */
    justify-content: flex-end;
    margin-bottom: 16px;
}
.breadCont{
    line-height: 42px;
    font-size: 14px;
    margin: 0 16px;
}
.container{
    /* display: flex; */
    padding: 20px;
    background-color: #fff;
    margin:16px;
}
.container::after{
    content: '';
    display: block;
    clear: both;
}
.groupList{
    float: left;
    width:200px;
    flex-grow: 0;
    background-color: #fff;
    min-height:100px;
    margin-right: 10px;
    /* flex-shrink: 0; */
    
}
.dataContain{
    width: calc(100% - 220px);
    /* flex: 1 1 auto; */
    float: right;
}