.phoneContent{
    position: fixed;
    z-index:10000;
    top:100px;
    left:300px;
    width:375px;
    height: 667px;
    background-color: #2e333b;
    user-select: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    -o-user-select: none;
}
.phoneText{
    position: absolute;
    width:100%;
    top:150px;
    left: 0;
    text-align:center;
    color:#f2f2f2;
}
.phoneText > h3{
    font-size: 30px;
    color: #f2f2f2;
    margin-bottom: 0;
}
.phoneText > h4{
    font-size: 14px;
    line-height: 28px;
    color: #f2f2f2;
    width:300px;
    text-align: center;
    margin: 0 auto;
}
.phoneStatus{
    line-height: 36px;
}
.phoneSystemInfo{
    height:12px;
    background-color: #eee;
}
.phoneOptionsContent{
    position: absolute;
    top: 380px;
    left: 0;
    width: 100%;
}
.phoneOption{
    display: flex;
    justify-content: space-around;
    align-items: center;
    color:rgba(255,255,255,0.8);
    font-size: 26px;
    margin-top:30px;
}
.phoneCallBtn{
    margin-top: 50px;
    text-align: center;
}
.scaleBtn{
    position: absolute;
    top:30px;
    right:23px;
    font-size: 28px;
    color: rgba(255,255,255,1);
    cursor: pointer;
}
.scaleBtn:hover{
    opacity: 0.6;
}
.phoneBtnItem{
    cursor: pointer;
}
.phoneBtnItem:hover{
    opacity:0.8;
}
.linesContent{
    display: flex;
    align-items: center;
    padding:5px 0;
}
.lineAnimation{
    width:3px;
    background-color: #ADCEFF;
    animation: lines 0.8s ease-out infinite;
    margin-right: 3px;
}
@keyframes lines {
    0% {
        height: 100%;
    }
    50%{
        height:4%;
    }
    100%{
        height:100%
    }
}
.userSelect{
    -moz-user-select: none;
    -webkit-user-select: none;
    user-select: none;
}
/*************缩略图*************/
.phoneMini{
    position: fixed;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding:0 12px;
    z-index:10000;
    top:400px;
    left:800px;
    width: 380px;
    height: 60px;
    line-height: 20px;
    background-color: #2e333b;
    user-select: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    -o-user-select: none;
}

.phoneIcon{
    /*-webkit-transform: rotateY(90deg);*/
    /*color:red;*/
    font-size: 16px;
    margin-right: 4px;
}
.phoneMiniState > div:first-child{
    color: #52c41a;
}
.phoneMiniState > div:last-child{
    color:#fff;
}
.phoneMiniBtnList{
    color:#fff;
}
.phoneMiniBtn{
    margin-right:12px;
    font-size: 20px;
    color:#fff;
}