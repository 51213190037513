.formContent{
    display: flex;
    justify-content: space-between;
}
.breadCont{
    line-height: 42px;
    font-size: 14px;
    margin: 0 16px;
}
.container{
    padding: 20px;
    background-color: #fff;
    margin:16px;
}