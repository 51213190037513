.layoutContainer{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}
.siteLayoutBackground{
    background: #fff;
    box-shadow: 0 0 8px rgba(0,0,0,0.2);
}
.silderContainer{
    /* background-color: #f2f2f2; */
    
}
.logo{
    width:100%;
    height: 64px;
    font-size: 26px;
    text-align: center;
    line-height: 64px;
    color: #fff;
}