.container{
    position: fixed;
    z-index: 1000;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-size: auto 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.loginContainer{
    display: flex;
    align-items: center;
    justify-content: flex-end;
    position: relative;
    width: 903px;
    height: 562px;
    padding-right: 20px;
}
.information{
    width:384px;
    float: right;
    margin-right: 26px;
    color:#000;
}